
<section class="bg-white">
    <div class="hero w-fullscreen">
        <h2 class="Font-Inter-Black title">Política de Privacidad</h2>
        <h2 class="Font-Inter-Black title-m">Política <br>de Privacidad </h2>
        <img src="assets/images/candado.png" alt="candado" class="key">
    </div>
</section>

<div class="bg-contenedor">
    <section class="parraf">
        <div class="Font-Helvetica-Roman">
            <h2>
                1.	Identificación del responsable del tratamiento
            </h2>
            <ol type="a">
                <li>Razón Social: TAX TECHNOLOGY S.A.C.</li>
                <li>Domicilio: Lima, Perú</li>
                <li>Dirección: Calle Cantuarias 160, Oficina 304, Miraflores.</li>
            </ol>

            <h2>2.	Marco legal aplicable</h2>

            <ol type="a">
                <li>Constitución Política, artículo 2, numeral 6.</li>
                <li>Ley de Protección de Datos Personales, Ley N° 29733.</li>
                <li>Reglamento de la Ley de Protección de Datos Personales, Decreto Supremo N° 003-2013-JUS.</li>
                <li>Directiva de Seguridad de la Información Administrada por los Bancos de Datos Personales emitida por la Autoridad Nacional de Protección de Datos Personales.</li>
                <li>Directivas y Resoluciones Directorales aplicables emitidas por la Dirección General de Protección de Datos Personales del Ministerio de Justicia y Derechos Humanos.</li>
                <li>Todas las disposiciones normativas aplicables que adicionen, modifiquen o complementen las normas antes indicadas.</li>
            </ol>

            <h2>3.	Objetivos de esta Política</h2>

            <p>TAX TECHNOLOGY S.A.C. (en adelante, TAX TECH) se encuentra comprometida con la protección de la información de carácter privada y confidencial que es obtenida dentro del giro ordinario de sus negocios. El presente documento tiene como finalidad definir los lineamientos mediante los cuales se dará un adecuado tratamiento y protección a los datos personales que se recopilan, almacenan y gestionan. Estos datos serán utizados por TAX TECH con el objetivo de poner en su conocimiento información relativa a los servicios que presta TAX TECH y los que pueda desarrollar en el futuro.</p>

            <p>Nuestras políticas y procedimientos tienen como base el marco legal indicado en el apartado precedente, cuyo fin es la protección de la información que nos ha sido confiada, siendo nuestra intención recolectar exclusivamente aquella información que ha sido suministrada voluntariamente.</p>
            
            <p>Debemos señalar que al suministrar, entregar o enviar cualquier tipo de información personal a TAX TECH, el Titular de los datos acepta que dicha información va a ser utilizada de acuerdo con la presente Política para el Tratamiento de Datos Personales. Es de aclarar que TAX TECH no utilizará los datos suministrados para actividades o propósitos diferentes a los aquí previstos. En todo caso, en el supuesto en el que dicha información personal sea utilizada para otros propósitos, su utilización deberá estar amparada en alguna de las excepciones que sobre el particular prevé la legislación aplicable o contar con la autorización expresa del Titular.</p>

            <p>La presente Política para el Tratamiento de Datos Personales aplica a todos los colaboradores de TAX TECH, así como a los terceros que realicen algún tratamiento de datos personales que estas recopilan, almacenan o gestionan.</p>

            <h2>4.	Definiciones</h2>

            <p>Para facilitar una mejor comprensión de la presente Política para el Tratamiento de Datos Personales, se podrá consultar las definiciones previstas en la Ley de Protección de Datos Personales, Ley N° 29733, y su Reglamento, Decreto Supremo N° 003-2013-JUS, o las normas que las complementen o modifiquen. A continuación, señalamos algunas definiciones relevantes para los fines de esta Política:</p>
            <div style="overflow-x:auto;">
                <table class="table table-bordered table-sm" cellpadding="0" cellspacing="0"> 
                    <tbody>
                    <tr>
                        <td width="20%" class="table-info text-center">
                            <h5 style="color:white;">Autorización</h5>
                        </td>
                        <td width="30%">Consentimiento previo, expreso e informado del titular del dato para llevar a cabo el tratamiento.
                        </td>
                        <td width="20%" class="table-info text-center">
                            <h5 style="color:white;">Aviso de privacidad</h5>
                        </td>
                        <td width="30%" class="text-center">Comunicación verbal o escrita generada por EY dirigida al Titular para el Tratamiento de sus datos personales, mediante la cual se le informa acerca de la existencia de las políticas de tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las finalidades del Tratamiento que se pretende dar a los datos personales.</td>
                    </tr>
                    <tr>
                        <td width="20%" class="table-info text-center">
                            <h5 style="color:white;">Datos personales</h5>
                        </td>
                        <td width="30%">
                            Es aquella información numérica, alfabética, gráfica, fotográfica, acústica, sobre hábitos personales, o de cualquier otro tipo concerniente a las personas naturales que las identifica o las hace identificables a través de medios que puedan ser razonablemente utilizados.
                        </td>
                        <td width="20%" class="table-info text-center">
                            <h5 style="color:white;">Datos sensibles</h5>
                        </td>
                        <td width="30%" class="text-center">
                            Es aquella información relativa a datos personales referidos a las características físicas, morales o emocionales, hechos o circunstancias de su vida afectiva o familiar, los hábitos personales que corresponden a la esfera más íntima, la información relativa a la salud física o mental u otras análogas que afecten su intimidad.
                        </td>
                    </tr>
                    <tr>
                        <td width="20%" class="table-info text-center">
                            <h5 style="color:white;">Encargado del Tratamiento</h5>
                        </td>
                        <td width="30%">
                            Persona que realiza el tratamiento de datos por cuenta del responsable del tratamiento.
                        </td>
                        <td width="20%" class="table-info text-center">
                            <h5 style="color:white;">Responsable del Tratamiento</h5>
                        </td>
                        <td width="30%" class="text-center">
                            Es aquél que decide sobre el tratamiento de datos personales, aun cuando no se encuentren en un banco de datos personales.
                        </td>
                    </tr>
                    <tr>
                        <td width="20%" class="table-info text-center">
                            <h5 style="color:white;">Titular de datos personales</h5>
                        </td>
                        <td width="30%">
                            Es toda aquella persona natural respecto de la cual se adquieren datos personales sujetos a tratamiento.
                        </td>
                        <td width="20%" class="table-info text-center">
                            <h5 style="color:white;">Tratamiento</h5>
                        </td>
                        <td width="30%" class="text-center">
                            Cualquier operación o conjunto de operaciones sobre datos personales como la recolección, el almacenamiento, el uso, la circulación o supresión de esa clase de información.
                        </td>
                    </tr>
                    <tr>
                        <td width="20%" class="table-info text-center">
                            <h5 style="color:white;">Transferencia</h5>
                        </td>
                        <td width="30%">
                                Actividad que tiene lugar cuando el Responsable y/o Encargado del Tratamiento de datos personales, ubicado en Perú, envía la información o los datos personales a un receptor, que a su vez es Responsable del Tratamiento y se encuentra ubicado dentro o fuera del país.
                        </td>
                        <td width="20%" class="table-info text-center">
                            <h5 style="color:white;">Transmisión</h5>
                        </td>
                        <td width="30%" class="text-center">
                            Tratamiento de datos personales que implica la comunicación de los mismos dentro (transmisión nacional) o fuera de Perú (transmisión internacional) y que tiene por objeto la realización de un tratamiento por el Encargado por cuenta del Responsable.
                        </td>
                    </tr>
                    <tr>
                        <td width="20%" class="table-info text-center">
                            <h5 style="color:white;">Cliente</h5>
                        </td>
                        <td width="30%">
                            Es aquella persona natural o jurídica que potencialmente puede mantener, mantiene o mantuvo una relación comercial con TAX TECH, pues ésta le prestó servicios.
                        </td>
                        <td width="20%" class="table-info text-center">
                            <h5 style="color:white;">Proveedor</h5>
                        </td>
                        <td width="30%" class="text-center">
                            Es aquella persona natural o jurídica que suministra bienes y/o servicios a TAX TECH en virtud de una relación comercial preexistente.
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <h2>5.	Principios rectores y otras reglas aplicables</h2>

            <ol type="i">
                <li>
                    Principios rectores
                    <ul>
                        <li>TAX TECH solo procederá a realizar el tratamiento de los datos personales si es lícito. Esto es, solo lo aplicará cuando el titular del dato personal hubiere prestado su consentimiento libre, previo, expreso, informado e inequívoco. Bajo ningún caso, se admitirán fórmulas de consentimiento en las que éste no sea expresado de forma directa, como aquellas en las que se requiere presumir, o asumir la existencia de una voluntad que no ha sido expresa.
                     
                            <p>El consentimiento del Titular podrá ser oral, escrito o digital. En el caso de tratamiento de datos sensibles, el consentimiento debe efectuarse por escrito de acuerdo a la legislación aplicable y los conceptos desarrollados</p>
                        </li>
                        <li>TAX TECH prohíbe la recopilación de los datos personales por medios fraudulentos, desleales o ilícitos.</li>
                        <li>TAX TECH expondrá de manera clara e inequívoca las razones por las cuales solicita la entrega de datos personales. Los fines de TAX TECH son lícitos y legítimos para el desarrollo de sus actividades.</li>
                        <li>TAX TECH guardará confidencialidad respecto de los datos personales y de sus antecedentes. Esta obligación subsiste aun después de finalizadas las relaciones con los Clientes y/o Proveedores. Esta obligación de confidencialidad se extingue cuando medie consentimiento previo, informado, expreso e inequívoco del Titular, resolución judicial consentida o ejecutoriada, o cuando medien razones fundadas relativas a la defensa nacional, seguridad pública o la sanidad pública, sin perjuicio del derecho a guardar el secreto profesional.</li>
                        <li>TAX TECH adoptará las medidas de seguridad necesarias a fin de evitar de proteger los datos personales del Titular que de manera libre y voluntaria los haya proporcionado. Para tal efecto, cada persona vinculada a TAX TECH deberá cumplir las medidas técnicas y administrativas que se establezcan para otorgar seguridad a los datos personales evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
                        
                            <p>En ese sentido, para garantizar la seguridad de los datos, TAX TECH brindará acceso restringido a las siguientes personas:</p>
                            <ol type="i">
                                <li>Al Titular del dato personal.</li>
                                <li>A las personas autorizadas por el Titular del dato personal.</li>
                                <li>A las personas que por mandato legal u orden judicial sean autorizadas para conocer la información del Titular del dato personal.</li>
                            </ol>
                         
                            <p>Los datos personales, salvo la información pública, no podrán estar disponibles en internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los titulares o terceros autorizados conforme a la ley.</p>
                        </li>
                        <li>TAX TECH buscará asegurar que en el tratamiento de datos personal se garantice el derecho del Titular a obtener en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan.</li>
                        <li>Las demás excepciones contenidas en la Ley de Protección de Datos Personales, Ley N° 29733, y su Reglamento, Decreto Supremo N° 003-2013-JUS.</li>
                    </ul>
                </li>
                <li>Supuestos en los que no será necesario el consentimiento del Titular
                    <ul>
                        <li>Cuando se trate de datos personales contenidos o destinados a ser contenidos en fuentes accesibles para el público.</li>
                        <li>Cuando los datos personales sean necesarios para la preparación, celebración y ejecución de una relación contractual en la que el Titular de datos personales sea parte.</li>
                        <li>
                            Cuando se hubiera aplicado un procedimiento de anonimización o disociación.
                        </li>
                        <li>Cuando el tratamiento de los datos personales sea necesario para salvaguardar intereses legítimos del Titular de datos personales por parte del titular de datos personales o por el encargado de tratamiento de datos personales.</li>
                    </ul>
                </li>
                <li>
                    Destinatarios
                  
                    <p>Salvo las excepciones anteriormente mencionadas, y dentro de los fines legítimos propios del objeto social de TAX TECH, en ningún caso suministrarán, distribuirán, comercializarán, compartirán, y, en general, realizarán actividad alguna en la cual se vea comprometida la confidencialidad y protección de la información recolectada.</p>
             
                    <p>Sin embargo, los datos que sean recolectados por TAX TECH podrán, eventualmente, ser compartidos con otras entidades miembros o vinculadas, directa o indirectamente con TAX TECH y con Terceros. En el evento que dicha información vaya a ser compartida con Terceros diferentes a otros miembros de TAX TECH o con Terceros que no sean clientes de TAX TECH o que no cuenten con interés sobre la información proporcionada, se deberá contar con su consentimiento y autorización antes de compartir dicha información.</p>
            
                    <p>TAX TECH se reserva el derecho de realizar análisis estadísticos, históricos, de mercadeo, o de otra naturaleza con base en los datos personales tratados, en tal caso podrá entregar los resultados a terceros, pero al hacerlo, no suministrará información personal sin la autorización de los titulares de datos personales.</p>
                </li>
            </ol>

            <h2>6.	Finalidad del tratamiento de datos personales </h2>
            <ol type="i">
                <li>En relación a los datos personales de Clientes
                
                    <p>TAX TECH podrán llevar a cabo el Tratamiento de la información personal acerca de sus Clientes con el objetivo de prestar los servicios profesionales por ellos contratados, o potenciales nuevos servicios, de conformidad con su objeto social. En ese sentido, los contratos que se celebren con Clientes se regirán por lo dispuesto en la presente Política, o incluirán una cláusula que regule el Tratamiento de la información a la que se acceda en virtud del mismo. Los datos que sean recolectados por TAX TECH podrán, eventualmente, ser compartidos con otras empresas del grupo, y con terceros o autoridades con la finalidad de desarrollar revisiones de calidad, llevar a cabo auditorías, actividades de supervisión o con el fin de cumplir con los estándares de calidad respecto de los servicios contratados por el Cliente. El Titular del dato personal acepta que su información sea almacenada en los servidores que TAX TECH contrate, locales o del exterior.</p>
               
                    <p>Bajo el principio de buena fe, TAX TECH asume que la información personal de terceros que deba conocer en razón o con ocasión de la prestación de los servicios contratados por el Cliente, ha sido tratada y entregada por el Cliente –en su calidad de Responsable del Tratamiento- a TAX TECH en apego estricto a lo dispuesto en la Ley de Protección de Datos Personales, Ley N° 29733, y su Reglamento, Decreto Supremo N° 003-2013- JUS. Lo anterior incluye que los datos de dichos terceros han sido tratados con autorización de su Titular, y que dicha autorización faculta al Cliente para procurar el Tratamiento de los datos por parte de TAX TECH. En todo caso, los datos personales de terceros cuyo Responsable del Tratamiento sea el Cliente serán tratados en el marco del objeto de los servicios contratados por el Cliente, a efectos de dar cumplimiento a la relación comercial que TAX TECGH haya entablado con el Cliente.</p>
             
                    <p>De igual manera, TAX TECH podrán llevar a cabo el tratamiento de la información personal de sus Clientes potenciales, actuales o futuros y de aquellos Clientes con los cuales haya terminado su relación comercial, con la finalidad de enviarles información comercial que a su juicio pueda ser de su interés; así como, invitarlos a eventos que organice, remitir boletines, informes sectoriales o publicaciones; y, en general, utilizar los datos para el desarrollo de las actividades comprendidas en su objeto social.</p>
             
                    <p>Asimismo, con ocasión de los eventos, capacitaciones o demás actividades llevadas a cabo, podrá grabar y tomar audios, videos o fotos de Clientes del desarrollo de éstos. Estos audios, videos o fotos podrán ser utilizados en boletines, informes sectoriales, publicaciones, y en general en cualquier actividad de TAX TECH.</p>
               
                    <p>Esta Política no será aplicada cuando el tratamiento de los datos personales del Cliente sea necesario para cumplir con las obligaciones en virtud de la relación comercial que se tenga con un Cliente. En estos casos, el Tratamiento de los datos del Cliente se regulará de conformidad con las estipulaciones del contrato o la orden de servicios respectiva.</p>

                </li>
                <li>
                    En relación a los datos personales de Proveedores
               
                    <p>TAX TECH llevará a cabo el Tratamiento de información personal de sus Proveedores con la finalidad de cumplir con las obligaciones adquiridas en virtud de la respectiva relación, verificar la existencia de posibles conflictos, viabilidad financiera, jurídica y comercial de una eventual relación comercial o mantener aquellas que se encuentran en desarrollo. El Titular del dato personal acepta que su información sea almacenada en los servidores que TAX TECH contrate, locales o del exterior.</p>
                
                    <p>En relación con los Proveedores, se incluye por ejemplo, evaluar su desempeño; establecer, gestionar o terminar las relaciones comerciales o verificar las referencias; proveer las métricas de negocio, gestionar solicitudes, quejas, reclamos, realizar encuentas de satisfacción, suministrar información de contacto a la fuerza comercial; y las demás que se establezcan en los acuerdos o contratos que se suscriban con el Proveedor respectivo.</p>
              
                    <p>El Tratamiento de los datos que se recolecten en virtud de lo dispuesto en el presente acápite será llevado a cabo y estará vigente mientras se mantenga la finalidad por la cual fueron recolectados los datos respectivos.</p>
                </li>
                <li>
                    En relación a los datos personales de empleados y/o trabajadores
               
                    <p>TAX TECH llevará a cabo el Tratamiento de información personal de sus empleados y su núcleo familiar con la finalidad de cumplir con las obligaciones que emanan de las respectivas relaciones laborales. Tales obligaciones incluyen, entre otras, pagos de planilla, otorgamiento de beneficios, evaluaciones de desempeño, mejoramiento de los programas de entrenamiento, entre otros. El Titular del dato personal acepta que su información sea almacenada en los servidores que TAX TECH contrate, locales o del exterior.</p>
                  
                    <p>TAX TECH, con ocasión de los eventos que realice, tales como capacitaciones u otros cursos de entrenamiento, podrá grabar y tomar audios, videos o fotos de los empleados, los cuales podrán ser utilizados en boletines, publicaciones; y, en general en cualquier actividad de TAX TECH, incluso una vez el empleado culmine su relación laboral.</p>
              
                    <p>TAX TECH tendrán un archivo en el cual almacenará indefinidamente los datos personales de todos los Empleados que hayan terminado su relación laboral. Este archivo tendrá como finalidad: (i) servir como base para la expedición de los certificados laborales o cualquier otro documento referido a la relación laboral de acuerdo con la normatividad laboral vigente, a solicitud del Ex Empleado o sus causahabientes; (ii) servir de base para el otorgamiento de referencias laborales a potenciales empleadores del Ex Empleado, cuando éste así lo autorice al momento de su desvinculación; y/o iii) la información almacenada en este archivo podrá ser estudiada, analizada y utilizada a efectos de considerar al Ex Empleado en los procesos de contratación de personal que se lleven a cabo.</p>
                 
                    <p>Estas mismas regalas aplicararán para los casos en los que TAX TECH recolecte información personal de aspirantes que se presenten para procesos de selección con la finalidad de poder adelantar los procesos respectivos de contratación de personal; por lo que, se entiende que quien participe en un proceso de selección y proporcione su hoja de vida, por cualquier medio, entiende y autoriza el Tratamiento de sus datos personales por parte de TAX TECH.</p>

                </li>
            </ol>

            <h2>7.	Derechos del Titular</h2>
            <ol type="i">
                <li>Derecho de información
                   
                    <p>El Titular de datos personales puede consultarle a TAX TECH sobre la finalidad para la que sus datos personales serán tratados, quiénes son o pueden ser los destinatarios, la existencia del banco de datos en que se almacenarán, la transferencia de los datos personales; las consecuencias de proporcionar sus datos personales y de su negativa a hacerlo; el tiempo durante el cual se conserven sus datos personales.</p>
                </li>
                <li>
                    Derecho de acceso
                
                    <p>El Titular de datos personales puede solicitar a TAX TECH su información que sea objeto de tratamiento, la forma en que sus datos fueron recopilados, las razones que motivaron su recopilación y a solicitud de quién se realizó la recopilación, así como las transferencias realizadas o que se prevén hacer de ellos.</p>
                </li>
                <li>
                    Derecho de actualización, inclusión, rectificación y supresión
                   
                    <p>El Titular de datos personales puede solicitar a TAX TECH la actualización, inclusión, rectificación y supresión de sus datos personales materia de tratamiento, cuando sean parcial o totalmente inexactos, incompletos, cuando se hubiere advertido omisión, error o falsedad, cuando hayan dejado de ser necesarios o pertinentes a la finalidad para la cual hayan sido recopilados o cuando hubiera vencido el plazo establecido para su tratamiento.</p>
                </li>
                <li>
                    Derecho a impedir el suministro
                 
                    <p>El Titular puede solicitar a TAX TECH que sus datos personales no sean suministrados, especialmente cuando ello afecte sus derechos fundamentales.</p>
                </li>
                <li>
                    Derecho de oposición
                
                    <p>En tanto que por ley no se disponga lo contrario y cuando no hubiera prestado consentimiento, el Titular de datos personales puede oponerse a su tratamiento cuando existan motivos fundados y legítimos relativos a una concreta situación personal. En caso de oposición justificada, TAX TECH procederá a su supresión, conforme a ley.</p>
                </li>
            </ol>

            <h2>8. Procedimiento para que el Titular ejerza sus derechos</h2>
            <p>El ejercicio de los derechos se lleva a cabo mediante solicitud escrita al área encargada indicada líneas abajo, la cual deberá ser entregada físicamente en la Mesa de Partes ubicada en Av. Paseo de la República 5663, Miraflores.</p>
            <p>Área Legal</p>
            <p>Correo Electrónico hola@taxtech.pe;</p>
            <p>Las solicitudes deberán contener los siguientes requisitos:</p>
            <ul>
                <li>
                    Nombres y apellidos del Titular del derecho, acreditación de su identidad presentando una copia simpre del Documento de Identidad; de ser el caso, del representante.
                </li>
                <li>
                    Petición concreta que da lugar a la solicitud.
                </li>
                <li>
                    Domicilio, o dirección que puede ser electrónica, a efectos de las notificaciones que correspondan.
                </li>
                <li>
                    Fecha y firma del solicitante.
                </li>
                <li>
                    Documentos que sustenten la petición, de ser el caso.
                </li>
            </ul>
            <p>Los plazos de respuesta son:</p>
            <ul>
                <li>
                    El plazo máximo de respuesta ante el ejercicio del derecho de información será de ocho
                    (08) días hábiles contados desde el día siguiente de la presentación de la solicitud.
                </li>
                <li>
                    El plazo máximo para la respuesta ante el ejercicio del derecho de acceso será de veinte (20) días hábiles contados desde el día siguiente de la presentación de la solicitud.
                </li>
                <li>
                    Tratándose del ejercicio de los otros derechos como los de rectificación, cancelación u oposición, el plazo máximo de respuesta del titular del banco de datos personales o responsable del tratamiento será de diez (10) hábiles contados desde el día siguiente de la presentación de la solicitud.
                </li>
            </ul>
            <p>
                En el caso que la información proporcionada en la solicitud sea insuficiente o errónea de forma que no permita su atención, TAX TECH podrá requerir al Titular, dentro de los siete (7) días hábiles siguientes de recibida la solicitud, documentación adicional para atenderla. En un plazo de diez (10) días de recibido el requerimiento, contado desde el día siguiente de la recepción del mismo, el Titular deberá acompañar la documentación adicional que estime pertinente para fundamentar su solicitud. En caso contrario, se tendrá por no presentada dicha solicitud.
            </p>
            <p>
                El Área Encargada enviará la respuesta respectiva al correo electrónico desde el cual se envió la inquietud y/o reclamo, o a la dirección que se señale en la comunicación. Toda comunicación que carezca de dirección física o electrónica para responder no se estudiará, y será descartada.
            </p>
            <h2>9.	Información de menores de edad</h2>
            <p>Dentro del ejercicio de sus actividades comerciales, TAX TECH no se involucrada con información de menos de edad, razón por la cual, en estricto cumplimiento de las normas legales vigentes, descarta la necesidad de recolectar datos de menos de edad. Sin embargo, si por alguna razón accede a esta información, serán datos de naturaleza pública cuyo tratamiento responderá a lo siguiente:
            </p>
            <ul>
                <li>Responda y respete el interés superior del menor de edad;</li>
                <li>Se asegure el respeto de sus derechos fundamentales.</li>
                <li>Sea en concordancia con el Código de los Niños y Adolescentes</li>
            </ul>
            <h2>10.	Transferencia internacional de datos personales </h2>
            <p>
                De conformidad con lo dispuesto en el artículo 16 la Ley de Protección de Datos Personales – Ley N° 29733, TAX TECH se compromete a no transferir datos a terceros países que no cumplan con los estándares de protección exigidos por la normativa sobre la materia, salvo las excepciones que se señalan a continuación:
            </p>

            <ul>
                <li>Información respecto de la cual el Titular haya otorgado su autorización expresa e inequívoca para la transferencia;</li>
                <li>
                    Cuando el intercambio de datos se realice para la protección, prevención, diagnóstico o tratamiento médico o quirúrgico de su titular; o cuando sea necesario para la realización de estudios epidemiológicos o análogos, en tanto se apliquen procedimientos de disociación adecuados;
                </li>
                <li>
                    Transferencias bancarias o bursátiles, conforme a la legislación que les resulte aplicable;
                </li>
                <li>
                    Transferencias acordadas en el marco de tratados internacionales en los cuales la República de Perú sea parte, con fundamento en el principio de reciprocidad;
                </li>
                <li>
                    Cuando los datos personales sean necesarios para la ejecución de una relación contractual en la que el titular de datos personales sea parte, incluyendo lo necesario para actividades como la autentificación de usuario, mejora y soporte del servicio, monitoreo de la calidad del servicio, soporte para el mantenimiento y facturación de la cuenta y aquellas actividades que el manejo de la relación contractual requiera.
                </li>
            </ul>
        </div>
    </section>
</div>

<!-- <app-contacto></app-contacto> -->

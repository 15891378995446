<div class="bg-white">
    <section class="hero w-fullscreen">
        <div class="hero-text">
            <img class="hero-icon" src="assets/images/plesmart2.png" alt="plesmart">
            <h2 class="color-blue Font-Inter-Black">{{ 'plesmart.hero.title' | translate }}</h2>

            <p class="color-blue Font-Helvetica-Roman">{{ 'plesmart.hero.parraf' | translate }}</p>

            <button class="btn-plesmart" (click)="ngContacto()">
                {{ 'plesmart.hero.btn' | translate }}
            </button>
        </div>
        <figure class="m-0">
            <img src="assets/images/phone2.png" alt="laptop" class="phone">
        </figure>
    </section>
</div>

<div class="bg-contenedor">
    <section class="solucion w-fullscreen">
        <div class="video-responsive">
<!--            <img src="assets/images/intuitiva.png" alt="intuitiva">-->
          <video controls>
            <source src="assets/videos/video_plesmart.mp4" type="video/mp4">
          </video>
        </div>
        <div class="text-video">
            <h2 class="color-blue Font-Inter-Black">{{ 'plesmart.solution.title' | translate }}</h2>
       
            <p class="color-blue Font-Helvetica-Roman">{{ 'plesmart.solution.parraf' | translate }}</p>

            <button mat-button class="color-purple Font-Inter-Bold" (click)="ngContacto()">
                {{ 'plesmart.solution.btn' | translate }}
              <mat-icon>
                arrow_right
              </mat-icon>
            </button>
        </div>
    </section>
</div>

<div class="bg-white">
    <section class="section-list w-fullscreen">

        <div class="text">
            <div class="item">
                <h2 class="color-blue Font-Inter-Black">{{ 'plesmart.list.title' | translate }}</h2>

                <div class="item-list">
                    <img src="assets/images/check-plesmart.png" alt="check">
                    <span class="color-blue Font-Helvetica-Roman parraf">{{ 'plesmart.list.parrafs.first' | translate }}</span>
                </div>

                <div class="item-list">
                    <img src="assets/images/check-plesmart.png" alt="check">
                    <span class="color-blue Font-Helvetica-Roman parraf">{{ 'plesmart.list.parrafs.second' | translate }}</span>
                </div>

                <div class="item-list">
                    <img src="assets/images/check-plesmart.png" alt="check">
                    <span class="color-blue Font-Helvetica-Roman parraf">{{ 'plesmart.list.parrafs.third' | translate }}</span>
                </div>

                <div class="item-list">
                    <img src="assets/images/check-plesmart.png" alt="check">
                    <span class="color-blue Font-Helvetica-Roman parraf">{{ 'plesmart.list.parrafs.fourth' | translate }}</span>
                </div>

                <div class="item-list">
                    <img src="assets/images/check-plesmart.png" alt="check">
                    <span class="color-blue Font-Helvetica-Roman parraf">{{ 'plesmart.list.parrafs.fifth' | translate }}</span>
                </div>
            </div>
        </div>
        <figure class="m-0" class="img-content">
            <img src="assets/images/plesmart-list.png" alt="plesmart">
        </figure>
    </section>
</div>

<app-contacto></app-contacto>

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent implements OnInit {
  anio: number;
  constructor(private location: Location, private router: Router) {}

  ngOnInit(): void {
    this.anio = new Date().getFullYear();
  }
  ngContacto(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.router
      .navigate([this.location.path()], { fragment: 'contacto' })
      .finally(() => {
        this.router.onSameUrlNavigation = 'ignore'; // Restore config after navigation completes
      });
  }
}

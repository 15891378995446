import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { TaxcheckerComponent } from '../taxchecker/taxchecker.component';
import { PlesmartComponent } from '../plesmart/plesmart.component';
import { DeducesmartComponent } from '../deducesmart/deducesmart.component';
import { TaxagileComponent } from '../taxagile/taxagile.component';
import { ConsultoriaComponent } from '../consultoria/consultoria.component';
import { PoliticaComponent } from '../politica/politica.component';
import { PrivacidadComponent } from '../privacidad/privacidad.component';
import { NosotrosComponent } from '../nosotros/nosotros.component';
import { TimeTaxSupportComponent } from '../time-tax-support/time-tax-support.component';

import { MetaGuard } from '@ngx-meta/core';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    canActivate: [MetaGuard],
    component: HomeComponent,
    data: {
      meta: {
        title: 'TaxTech - home',
        description: 'Tú sabes lo que exige la Administración Tributaria, nosotros te ayudamos a cumplirlo.',
        image: 'https://taxtech-web.s3.us-east-2.amazonaws.com/assets/images/graph.png',
        url: 'https://www.taxtech.pe/home',
        author: 'TaxTech'
      }
    }
  },
  {
    path: 'taxchecker',
    canActivate: [MetaGuard],
    component: TaxcheckerComponent,
    data: {
      meta: {
        title: 'TaxTech - TaxChecker',
        description: 'Valida tus comprobantes de pago electrónicos con precisión y rapidez.',
        image: 'https://taxtech-web.s3.us-east-2.amazonaws.com/assets/images/graph.png',
        url: 'https://www.taxtech.pe/taxchecker',
        author: 'TaxTech'
      }
    }
   },
  {
    path: 'plesmart',
    canActivate: [MetaGuard],
    component: PlesmartComponent,
    data: {
      meta: {
        title: 'TaxTech - Plesmart',
        description: 'Revisa la consistencia de la información de los libros electrónicos.',
        image: 'https://taxtech-web.s3.us-east-2.amazonaws.com/assets/images/graph.png',
        url: 'https://www.taxtech.pe/plesmart',
        author: 'TaxTech'
      }
    }
  },
  {
    path: 'deducesmart',
    canActivate: [MetaGuard],
    component: DeducesmartComponent,
    data: {
      meta: {
        title: 'TaxTech - DeduceSmart',
        description: 'Revisa la consistencia de la información de los libros electrónicos.',
        image: 'https://taxtech-web.s3.us-east-2.amazonaws.com/assets/images/graph.png',
        url: 'https://www.taxtech.pe/deducesmart',
        author: 'TaxTech'
      }
    }
   },
  {
    path: 'taxagile',
    canActivate: [MetaGuard],
    component: TaxagileComponent,
    data: {
      meta: {
        title: 'TaxTech - TaxAgile',
        description: 'Relevamos y optimizamos procesos que impactan en la determinación de impuestos.',
        image: 'https://taxtech-web.s3.us-east-2.amazonaws.com/assets/images/graph.png',
        url: 'https://www.taxtech.pe/taxagile',
        author: 'TaxTech'
      }
    }
   },
  {
    path: 'timetaxsupport',
    canActivate: [MetaGuard],
    component: TimeTaxSupportComponent,
    data: {
      meta: {
        title: 'TaxTech - TimeTaxSupport',
        description: 'Relevamos y optimizamos procesos que impactan en la determinación de impuestos.',
        image: 'https://taxtech-web.s3.us-east-2.amazonaws.com/assets/images/graph.png',
        url: 'https://www.taxtech.pe/timetaxsupport',
        author: 'TaxTech'
      }
    }
  },
  {
    path: 'consultoriaespecializada',
    canActivate: [MetaGuard],
    component: ConsultoriaComponent,
    data: {
      meta: {
        title: 'TaxTech - Consultoria Especializada',
        description: 'Amplia experiencia en fiscalizaciones y consultoría tributaria.',
        image: 'https://taxtech-web.s3.us-east-2.amazonaws.com/assets/images/graph.png',
        url: 'https://www.taxtech.pe/consultoriaespecializada',
        author: 'TaxTech'
      }
    }
  },
  {
    path: 'politica-seguridad',
    canActivate: [MetaGuard],
    component: PoliticaComponent,
    data: {
      meta: {
        title: 'TaxTech - Política de Seguridad',
        description: 'Tax Technology reconoce la importancia y el valor de la información.',
        image: 'https://taxtech-web.s3.us-east-2.amazonaws.com/assets/images/graph.png',
        url: 'https://www.taxtech.pe/politica-seguridad',
        author: 'TaxTech'
      }
    }
  },
  {
    path: 'politica-privacidad',
    canActivate: [MetaGuard],
    component: PrivacidadComponent,
    data: {
      meta: {
        title: 'TaxTech - Política de Privacidad',
        description: 'TAX TECH se encuentra comprometida con la protección de la información de carácter privada y confidencial que es obtenida dentro del giro ordinario de sus negocios.',
        image: 'https://taxtech-web.s3.us-east-2.amazonaws.com/assets/images/graph.png',
        url: 'https://www.taxtech.pe/politica-privacidad',
        author: 'TaxTech'
      }
    }
  },
  {
    path: 'nosotros',
    canActivate: [MetaGuard],
    component: NosotrosComponent,
    data: {
      meta: {
        title: 'TaxTech - Nosotros',
        description: 'Somos una empresa enfocada en el desarrollo de herramientas tecnológicas para atender procesos tributarios.',
        image: 'https://taxtech-web.s3.us-east-2.amazonaws.com/assets/images/graph.png',
        url: 'https://www.taxtech.pe/nosotros',
        author: 'TaxTech'
      }
    }
  },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'ignore',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    }),
    TranslateModule
  ],
  exports: [RouterModule],
})
export class RoutingModule {}

<div class="bg-white">
    <footer class="w-fullscreen">
        <div class="footer-row">
            <div class="text">
                <img src="assets/images/logo.png" alt="taxtech">
                <p class="Font-Helvetica-Roman color-blue parraf">{{ 'footer.title' | translate }}</p>

            </div>
            <div class="ul-group">
                <ul class="Font-Helvetica-Roman color-blue">
                    <li class="Font-Inter-SemiBold">
                        <a class="color-purple Font-Inter-Bold">{{ 'footer.company.title' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="nosotros" class="color-blue">{{ 'footer.company.first' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="politica-seguridad" class="color-blue">{{ 'footer.company.second' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="politica-privacidad" class="color-blue">{{ 'footer.company.third' | translate }}</a>
                    </li>
                    <li>
                        <a class="color-blue" (click)="ngContacto()">{{ 'footer.company.fourth' | translate }}</a>
                    </li>
                </ul>
                <ul class="Font-Helvetica-Roman color-blue">
                    <li class="Font-Inter-SemiBold">
                        <a class="color-purple Font-Inter-Bold">{{ 'footer.services.title' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="taxagile" class="color-blue">{{ 'footer.services.first' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="consultoriaespecializada" class="color-blue">{{ 'footer.services.second' | translate }}</a>
                    </li>
                </ul>
                <ul class="Font-Helvetica-Roman color-blue">
                    <li class="Font-Inter-SemiBold">
                        <a class="color-purple Font-Inter-Bold">{{ 'footer.tools.title' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="taxchecker" class="color-blue">{{ 'footer.tools.first' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="deducesmart" class="color-blue">{{ 'footer.tools.second' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="plesmart" class="color-blue">{{ 'footer.tools.third' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="timetaxsupport" class="color-blue">{{ 'footer.tools.fourth' | translate }}</a>
                    </li>
                    <li>
                        <a href="http://www.eol.pe/" target="_blank" rel="noopener" class="color-blue">{{ 'footer.tools.fifth' | translate }}</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="icons">
            <ul>
                <li>
                    <a href="https://www.linkedin.com/company/taxtechperu" target="_blank" rel="noopener" class="linkedin"></a>
                </li>
                <li>
                    <a href="https://www.facebook.com/TaxTech" target="_blank" rel="noopener" class="facebook"></a>
                </li>
            </ul>
            <p class="color-blue Font-Helvetica-Roman">© {{this.anio}} {{ 'footer.rights' | translate }}</p>
        </div>
    </footer>
</div>

<div class="bg-certificacion">
    <section class="certificacion w-fullscreen">
        <div class="img-content">
            <img src="assets/images/certificacion.png" alt="nosotros">
        </div>
        <div class="text">
            <h2 class="color-blue Font-Inter-Black title">{{ 'certification.title' | translate }}</h2>
            <p class="color-blue-soft Font-Helvetica-Roman parraf"> {{ 'certification.parraf' | translate }} </p>
    
            <button mat-button class="color-purple Font-Inter-Bold" (click)="ngContacto()">
                {{ 'certification.btn' | translate }}
              <mat-icon>
                arrow_right
              </mat-icon>
            </button>
        </div>
    
    </section>
</div>

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-plesmart',
  templateUrl: './plesmart.component.html',
  styleUrls: ['./plesmart.component.sass'],
})
export class PlesmartComponent implements OnInit {
  constructor(
    private location: Location, 
    private router: Router, 
    protected $gaService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.$gaService.pageView('/plesmart', 'Vista PLESmart');
  }
  ngContacto(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.router
      .navigate([this.location.path()], { fragment: 'contacto' })
      .finally(() => {
        this.router.onSameUrlNavigation = 'ignore'; // Restore config after navigation completes
      });
  }
}

<div class="title-dialog">
    <h3 class="color-blue font-12 Font-Inter-SemiBold mb-2"> {{ 'header.dialog.tools.title' | translate }}
        <img src="assets/images/arrow-down.png" alt="arrow-down">
    </h3>
    <button mat-icon-button mat-dialog-close class="closeDialog">
      <mat-icon class="color-primary">
        close
      </mat-icon>
    </button>
</div>
<div>
    <div class="item-dialog mb-1" (click)="goSection('taxchecker')">
        <img class="imgIcon" src="assets/images/taxchecker-circle.png" alt="TaxChecker">
        <p class="color-blue Font-Inter-SemiBold font-14 pl-1 m-0">TaxChecker</p>
    </div>
    <div class="item-dialog mb-1" (click)="goSection('deducesmart')">
        <img class="imgIcon" src="assets/images/deducesmart-circle.svg" alt="DeduceSmart">
        <p class="color-blue Font-Inter-SemiBold font-14 pl-1 m-0">DeduceSmart</p>
    </div>
    <div class="item-dialog mb-1" (click)="goSection('plesmart')">
        <img class="imgIcon" src="assets/images/plesmart-circle.png" alt="PleSmart">
        <p class="color-blue Font-Inter-SemiBold font-14 pl-1 m-0">PLESmart</p>
    </div>
    <div class="item-dialog mb-1" (click)="goSection('timetaxsupport')">
        <img class="imgIcon" src="assets/images/timetaxsupport-circle.png" alt="TimeTaxSupport">
        <p class="color-blue Font-Inter-SemiBold font-14 pl-1 m-0">TimeTaxSupport</p>
    </div>
    <a href="http://www.eol.pe/" target="_blank" rel="noopener">
        <div class="item-dialog mb-1" (click)="goSection('emiteonline')">
            <img class="imgIcon" src="assets/images/emiteonline-circle.svg" alt="EmiteOnline">
            <p class="color-blue Font-Inter-SemiBold font-14 pl-1 m-0">EmiteOnline</p>
        </div>
    </a>
</div>
<mat-divider></mat-divider>
<div class="title-dialog">
    <h3 class="color-blue font-12 Font-Inter-SemiBold mb-2">{{ 'header.dialog.services.title' | translate }}
        <img src="assets/images/arrow-down.png" alt="arrow-down">
    </h3>
</div>
<div>
    <div class="item-dialog mb-1" (click)="goSection('taxagile')">
        <img class="imgIcon" src="assets/images/taxagile-circle.svg" alt="TaxAgile">
        <p class="color-blue Font-Inter-SemiBold font-14 pl-1 m-0">TaxAgile</p>
    </div>
    <div class="item-dialog mb-1" (click)="goSection('consultoriaespecializada')">
        <img class="imgIcon" src="assets/images/consultoria-circle.svg" alt="Consultoría Especializada">
        <p class="color-blue Font-Inter-SemiBold font-14 pl-1 m-0">{{ 'header.dialog.services.second' | translate }}</p>
    </div>
</div>
<mat-divider></mat-divider>
<div class="title-dialog">
    <h3 class="color-blue font-12 Font-Inter-SemiBold" (click)="goSection('nosotros')">{{ 'header.dialog.us.title' | translate }}</h3>
</div>

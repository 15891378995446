<div class="bg-white">
    <section class="hero w-fullscreen">
        <div class="hero-text">
            <h1 class="color-blue Font-Inter-Black">{{ 'home.hero.title' | translate }}</h1>

            <p class="color-blue-soft Font-Helvetica-Roman">{{ 'home.hero.parraf' | translate }}</p>
            <button class="btn-rose" (click)="ngContacto()">{{ 'home.hero.btn' | translate }}</button>
        </div>
        <figure class="m-0">
            <img src="assets/images/phone.png" alt="phone">
        </figure>
    </section>
</div>

<div class="bg-contenedor">
    <section class="contenedor w-fullscreen">
        <div class="herramientas">
            <h2 class="color-blue Font-Inter-Black first">{{ 'home.tools.title' | translate }}</h2>

            <p class="Font-Helvetica-Roman color-blue-soft parraf">{{ 'home.tools.parraf' | translate }}</p>

            <div class="carousel">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide>

                        <mat-card class="herramientas-card">
                            <mat-card-content>
                                <img src="assets/images/taxchecker-circle.png" alt="taxchecker">
                                <p class="color-blue Font-Inter-Black title-card m-0">
                                    {{ 'home.tools.carousel.first.title' | translate }}
                                </p>
                                <p class="color-blue-soft Font-Helvetica-Roman parraf-card">{{ 'home.tools.carousel.first.parraf' | translate }}</p>
                            </mat-card-content>
                            <mat-card-actions>
                                <a mat-button class="color-blue Font-Inter-Bold btn-taxchecker" (click)="goSection('taxchecker')">
                                    {{ 'home.tools.carousel.first.btn' | translate }}
                                <mat-icon>
                                  arrow_right
                                </mat-icon>
                              </a>
                            </mat-card-actions>
                        </mat-card>
                    </ng-template>
                    <ng-template carouselSlide>
                        <mat-card class="herramientas-card">

                            <mat-card-content>
                                <img src="assets/images/deducesmart-circle.svg" alt="deducesmart">
                                <p class="color-blue Font-Inter-Black title-card m-0">
                                    {{ 'home.tools.carousel.second.title' | translate }}
                                </p>
                                <p class="color-blue-soft Font-Helvetica-Roman parraf-card">{{ 'home.tools.carousel.second.parraf' | translate }}</p>
                            </mat-card-content>
                            <mat-card-actions>
                                <a mat-button class="color-blue Font-Inter-Bold btn-deducesmart" (click)="goSection('deducesmart')">
                                    {{ 'home.tools.carousel.second.btn' | translate }}
                                <mat-icon>
                                  arrow_right
                                </mat-icon>
                              </a>
                            </mat-card-actions>
                        </mat-card>
                    </ng-template>
                    <ng-template carouselSlide>
                        <mat-card class="herramientas-card">
                            <mat-card-content>
                                <img src="assets/images/plesmart-circle.png" alt="plesmart">
                                <p class="color-blue Font-Inter-Black title-card m-0">
                                    {{ 'home.tools.carousel.third.title' | translate }}
                                </p>
                                <p class="color-blue-soft Font-Helvetica-Roman parraf-card"> {{ 'home.tools.carousel.third.parraf' | translate }}</p>
                            </mat-card-content>
                            <mat-card-actions>
                                <a mat-button class="color-blue Font-Inter-Bold btn-emiteonline" (click)="goSection('plesmart')">
                                    {{ 'home.tools.carousel.third.btn' | translate }}
                                <mat-icon>
                                  arrow_right
                                </mat-icon>
                              </a>
                            </mat-card-actions>
                        </mat-card>
                    </ng-template>
                    <ng-template carouselSlide>
                        <mat-card class="herramientas-card">
                            <mat-card-content>
                                <img src="assets/images/timetaxsupport-circle.png" alt="TimeTaxSupport">
                                <p class="color-blue Font-Inter-Black title-card m-0">
                                    {{ 'home.tools.carousel.fourth.title' | translate }}
                                </p>
                                <p class="color-blue-soft Font-Helvetica-Roman parraf-card"> {{ 'home.tools.carousel.fourth.parraf' | translate }}</p>
                            </mat-card-content>
                            <mat-card-actions>
                                <a mat-button class="color-blue Font-Inter-Bold btn-emiteonline" (click)="goSection('timetaxsupport')">
                                    {{ 'home.tools.carousel.fourth.btn' | translate }}
                                <mat-icon>
                                  arrow_right
                                </mat-icon>
                              </a>
                            </mat-card-actions>
                        </mat-card>
                    </ng-template>
                    <ng-template carouselSlide>
                        <mat-card class="herramientas-card">
                            <mat-card-content>
                                <img src="assets/images/emiteonline-circle.svg" alt="emiteonline">
                                <p class="color-blue Font-Inter-Black title-card m-0">
                                    {{ 'home.tools.carousel.fifth.title' | translate }}
                                </p>
                                <p class="color-blue-soft Font-Helvetica-Roman parraf-card"> {{ 'home.tools.carousel.fifth.parraf' | translate }}</p>
                            </mat-card-content>
                            <mat-card-actions>
                                <a mat-button class="color-blue Font-Inter-Bold btn-emiteonline" href="http://www.eol.pe/" target="_blank" rel="noopener">
                                    {{ 'home.tools.carousel.fifth.btn' | translate }}
                                <mat-icon>
                                  arrow_right
                                </mat-icon>
                              </a>
                            </mat-card-actions>
                        </mat-card>
                    </ng-template>
                </owl-carousel-o>
            </div>

        </div>
        <div class="rectangle"></div>

        <div class="servicios">
            <h2 class="color-blue Font-Inter-Black second">{{ 'home.services.title' | translate }}</h2>

            <p class="Font-Helvetica-Roman color-blue-soft parraf">{{ 'home.services.parraf' | translate }}</p>

            <div class="servicios-content">

                <img class="computer" src="assets/images/servicios.png" alt="computer">

                <div class="content-card">
                    <mat-card class="servicios-card">
                        <div class="content">
                            <img src="assets/images/taxagile-circle.svg" alt="taxagile">

                            <div class="text">
                                <p class="color-blue Font-Inter-Black title-card m-0">
                                    {{ 'home.services.cards.first.title' | translate }}
                                </p>
                                <p class="color-blue-soft Font-Helvetica-Roman parraf-card">{{ 'home.services.cards.first.parraf' | translate }}</p>

                                <a mat-button class="Font-Inter-Bold btn-taxagile" (click)="goSection('taxagile')">
                                    {{ 'home.services.cards.first.btn' | translate }}
                                  <mat-icon>
                                    arrow_right
                                  </mat-icon>
                                </a>
                            </div>

                            <!-- <mat-icon class="color-blue-light arrow-d">
                                arrow_right
                            </mat-icon> -->
                        </div>

                    </mat-card>
                    <mat-card class="servicios-card">
                        <div class="content">
                            <img src="assets/images/consultoria-circle.svg" alt="consultoria especilizada">

                            <div class="text">
                                <p class="color-blue Font-Inter-Black title-card m-0">
                                    {{ 'home.services.cards.second.title' | translate }}
                                </p>

                                <p class="color-blue-soft Font-Helvetica-Roman parraf-card"> {{ 'home.services.cards.second.parraf' | translate }}</p>

                                <a mat-button class="Font-Inter-Bold btn-consultoria" (click)="goSection('consultoriaespecializada')">
                                    {{ 'home.services.cards.second.btn' | translate }}
                                  <mat-icon>
                                    arrow_right
                                  </mat-icon>
                                </a>
                            </div>

                            <!-- <mat-icon class="color-rose arrow-d">
                                arrow_right
                            </mat-icon> -->
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </section>
</div>

<app-elegirtaxtech></app-elegirtaxtech>
<app-clientes></app-clientes>
<app-certificacion></app-certificacion>

<app-contacto></app-contacto>

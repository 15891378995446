import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-time-tax-support',
  templateUrl: './time-tax-support.component.html',
  styleUrls: ['./time-tax-support.component.sass']
})
export class TimeTaxSupportComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router,
    protected $gaService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.$gaService.pageView('/plesmart', 'Vista PLESmart');
  }

  ngContacto(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.router
      .navigate([this.location.path()], { fragment: 'contacto' })
      .finally(() => {
        this.router.onSameUrlNavigation = 'ignore'; // Restore config after navigation completes
      });
  }
}

<div class="bg-clientes">
    <section class="clientes w-fullscreen">
        <p class="Font-Inter-Bold color-blue title">{{ 'customs.title' | translate }}</p>

        <div class="clientes-images-content">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                    <div class="clientes-images">
                        <img class="img-fluid" src="assets/images/sacyr.svg" alt="sacyr">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="clientes-images">
                        <img class="img-fluid" src="assets/images/ford.svg" alt="ford">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="clientes-images">
                        <img class="img-fluid" src="assets/images/claro.svg" alt="claro">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="clientes-images">
                        <img class="img-fluid" src="assets/images/wework.svg" alt="wework">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="clientes-images">
                        <img class="img-fluid" src="assets/images/rotoplas.svg" alt="rotoplas">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="clientes-images">
                        <img class="img-fluid" src="assets/images/payless.png" alt="payless">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="clientes-images">
                        <img class="img-fluid pc" src="assets/images/pc.svg" alt="pc">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </section>
    
</div>

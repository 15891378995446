<div class="bg-white">
    <section class="hero w-fullscreen">
        <div class="hero-text">
            <img class="hero-icon" src="assets/images/logo-TimeTaxSupport.png" alt="TimeTaxSupport" />
            <h2 class="color-blue Font-Inter-Black">
                {{ "timetaxsupport.hero.title" | translate }}
                <!-- <span class="f-cursivo"> {{ "timetaxsupport.hero.cursivo" | translate }}</span> -->
            </h2>

            <p class="color-blue Font-Helvetica-Roman">
                {{ "timetaxsupport.hero.parraf" | translate }}
            </p>

            <button class="btn-tts" (click)="ngContacto()">
                {{ "timetaxsupport.hero.btn" | translate }}
            </button>
        </div>
        <figure class="m-0">
            <img src="assets/images/tts-hero.png" alt="TimeTaxSupport" class="phone" />
        </figure>
    </section>
</div>

<div class="bg-contenedor">
    <section class="defense w-fullscreen">
        <figure class="img-content m-0">
            <img src="assets/images/tts_capture.png" alt="TimeTaxSupport" class="phone" />
        </figure>
        <div class="text">
            <h2 class="color-blue Font-Inter-Black">
                {{ "timetaxsupport.defensefile.title" | translate }}
              <!-- <span class="f-cursivo"> {{ "timetaxsupport.defensefile.cursivo" | translate }}</span> -->
            </h2>

            <p class="color-blue Font-Helvetica-Roman">
                {{ "timetaxsupport.defensefile.parraf" | translate }}
            </p>

            <button mat-button class="color-purple Font-Inter-Bold" (click)="ngContacto()">
                {{ "timetaxsupport.defensefile.btn" | translate }}
                <mat-icon> arrow_right </mat-icon>
            </button>
        </div>
    </section>
</div>

<div class="bg-contenedor">
    <section class="solucion-video w-fullscreen">
        <div class="text-video">
            <h2 class="color-blue Font-Inter-Black">
                {{ "timetaxsupport.solution.title" | translate }}
            </h2>

            <p class="color-blue Font-Helvetica-Roman">
                {{ "timetaxsupport.solution.parraf" | translate }}
            </p>

            <button mat-button class="color-purple Font-Inter-Bold" (click)="ngContacto()">
                {{ "timetaxsupport.solution.btn" | translate }}
                <mat-icon> arrow_right </mat-icon>
            </button>
        </div>
        <div class="video-responsive">
            <!--            <img src="assets/images/intuitiva.png" alt="intuitiva">-->
            <video controls>
                <source src="assets/videos/video_tts.mp4" type="video/mp4" />
            </video>
        </div>
    </section>
</div>

<div class="bg-white">
    <section class="section-list w-fullscreen">
        <div class="text">
            <div class="item">
                <h2 class="color-blue Font-Inter-Black">
                    {{ "timetaxsupport.list.title" | translate }}
                </h2>

                <div class="item-list">
                    <img src="assets/images/check-azul-tts.png" alt="check" />
                    <span class="color-blue Font-Helvetica-Roman parraf">{{
                        "timetaxsupport.list.parrafs.first" | translate
                        }}</span>
                </div>

                <div class="item-list">
                    <img src="assets/images/check-azul-tts.png" alt="check" />
                    <span class="color-blue Font-Helvetica-Roman parraf">{{
                        "timetaxsupport.list.parrafs.second" | translate
                        }}</span>
                </div>

                <div class="item-list">
                    <img src="assets/images/check-azul-tts.png" alt="check" />
                    <span class="color-blue Font-Helvetica-Roman parraf">{{
                        "timetaxsupport.list.parrafs.third" | translate
                        }}</span>
                </div>

                <div class="item-list">
                    <img src="assets/images/check-azul-tts.png" alt="check" />
                    <span class="color-blue Font-Helvetica-Roman parraf">{{
                        "timetaxsupport.list.parrafs.fourth" | translate
                        }}</span>
                </div>

                <div class="item-list">
                    <img src="assets/images/check-azul-tts.png" alt="check" />
                    <span class="color-blue Font-Helvetica-Roman parraf">{{
                        "timetaxsupport.list.parrafs.fifth" | translate
                        }}</span>
                </div>
            </div>
        </div>
        <figure class="m-0 img-content">
            <img src="assets/images/tts-list.png" alt="TimeTaxSupport" />
        </figure>
    </section>
</div>

<app-contacto></app-contacto>
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      940: {
        items: 3,
      },
      1280: {
        items: 4,
      },
    },
    nav: false,
  };

  constructor(
    private router: Router,
    private location: Location
  ) {
  }

  ngOnInit(): void {}

  ngContacto(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.router
      .navigate([this.location.path()], { fragment: 'contacto' })
      .finally(() => {
        this.router.onSameUrlNavigation = 'ignore'; // Restore config after navigation completes
      });
  }

  goSection(ruta): void {
    this.router.navigate([`/${ruta}`]);
  }

}


<section class="bg-white">
    <div class="hero w-fullscreen">
        <h2 class="Font-Inter-Black title">Política de Seguridad </h2>
        <h2 class="Font-Inter-Black title-m">Política  <br> de Seguridad </h2>
        <img src="assets/images/candado.png" alt="candado" class="key">
    </div>
</section>

<div class="bg-contenedor">
    <section class="w-fullscreen">
        <div class="parraf Font-Helvetica-Roman">
            <p>
                Tax Technology reconoce la importancia y el valor de la información; por esta razón, nuestra política regula su manejo, orientada a establecer las medidas que resguarden la confidencialidad, integridad y disponibilidad de la información propia de la organización,
                su acceso de conformidad con las normas vigentes, así como asegurar la continuidad de nuestros servicios. La Gerencia asume la responsabilidad de implantar, mantener y mejorar continuamente un Sistema de Gestión de la Seguridad de la Información
                (SGSI) que permita lograr niveles adecuados de seguridad para todos los activos de información considerados relevantes, y así garantizar que los riesgos de la seguridad de la información sean conocidos, asumidos, gestionados y minimizados
                de una forma documentada, sistemática, estructurada y adaptada a los cambios que se produzcan en los riesgos, el entorno y las tecnologías. De ahí que resulte de vital importancia contar con un marco general donde se encuadren los procesos
                asociados a la gestión de la seguridad de la información, definiendo sus objetivos, aplicabilidad, forma de comunicación, roles y responsabilidades y el marco general para elaboración y revisión de las políticas de seguridad específicas para
                la información. Como parte del proceso de revisión gerencial, Tax Technology se compromete a revisar con regularidad esta Política, o en la medida que se sugieran cambios en el desarrollo del negocio, estructura, objetivos o estrategias que
                involucren aspectos afines. Tax Technlology se compromete a cumplir con los aspectos legales, regulatorios y contractuales que involucren el cuidado de la información.
            </p>
        </div>
    </section>
</div>

<app-contacto></app-contacto>

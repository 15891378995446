<div class="bg-white">
    <section class="hero w-fullscreen">
        <div class="hero-text">
            <img src="assets/images/deducesmart-rose.png" alt="deducesmart">
            <h2 class="color-blue Font-Inter-Black">{{ 'deducesmart.hero.title' | translate }}</h2>
       
            <p class="color-blue Font-Helvetica-Roman">{{ 'deducesmart.hero.parraf' | translate }}</p>
    
            <button class="btn-rose" (click)="ngContacto()">
                {{ 'deducesmart.hero.btn' | translate }}
            </button>
        </div>
        <figure class="m-0">
            <img src="assets/images/laptop3.png" alt="laptop" class="laptop">
        </figure>
    </section>
</div>

<div class="bg-contenedor">
    <section class="solucion w-fullscreen">
        <div class="img-content">
            <img src="assets/images/balanza.png" alt="balanza">
        </div>
        <div class="text">
            <h2 class="color-blue Font-Inter-Black">{{ 'deducesmart.solution.title' | translate }}</h2>

            <p class="color-blue Font-Helvetica-Roman">{{ 'deducesmart.solution.parraf' | translate }}</p>
    
            <button mat-button class="color-purple Font-Inter-Bold" (click)="ngContacto()">
                {{ 'deducesmart.solution.btn' | translate }}
              <mat-icon>
                arrow_right
              </mat-icon>
            </button>
        </div>
    </section>
</div>

<div class="bg-white">
    <section class="section-list w-fullscreen">

        <div class="text">
            <div class="item">
                <h2 class="color-blue Font-Inter-Black title">{{ 'deducesmart.list.title' | translate }}</h2>
               
                <div class="item-list">
                    <img src="assets/images/check-rose.svg" alt="check">
                    <span class="color-blue Font-Helvetica-Roman parraf">{{ 'deducesmart.list.parrafs.first' | translate }}</span>
                   
                </div>
                 
                <div class="item-list">
                    <img src="assets/images/check-rose.svg" alt="check">
                    <span class="color-blue Font-Helvetica-Roman parraf">{{ 'deducesmart.list.parrafs.second' | translate }}</span>
                   
                </div>
                 
                <div class="item-list">
                    <img src="assets/images/check-rose.svg" alt="check">
                    <span class="color-blue Font-Helvetica-Roman parraf">{{ 'deducesmart.list.parrafs.third' | translate }}</span>
                   
                </div>
                 
                <div class="item-list">
                    <img src="assets/images/check-rose.svg" alt="check">
                    <span class="color-blue Font-Helvetica-Roman parraf">{{ 'deducesmart.list.parrafs.fourth' | translate }}</span>
              
                </div>
                  
                <div class="item-list">
                    <img src="assets/images/check-rose.svg" alt="check">
                    <span class="color-blue Font-Helvetica-Roman parraf">{{ 'deducesmart.list.parrafs.fifth' | translate }}</span>
                  
                </div>
            </div>
        </div>
        <figure class="m-0" class="img-content">
            <img src="assets/images/deducesmart-list.png" alt="laptop" class="deducesmart">
        </figure>
    </section>
</div>


<app-contacto></app-contacto>

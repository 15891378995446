<div class="bg-white">
  <section class="hero w-fullscreen">
    <div class="hero-text">
        <div class="title-icon">
            <img src="assets/images/taxagile-circle.svg" alt="taxagile">
            <span class="color-blue-second Font-Inter-ExtraBold tagile">Tax Agile</span>
            <span class="color-blue Font-Inter-ExtraBold tech">TaxTech</span>
        </div>
        <h2 class="color-blue Font-Inter-Black">{{ 'taxagile.hero.title' | translate }}</h2>

        <p class="color-blue Font-Helvetica-Roman">{{ 'taxagile.hero.parraf' | translate }}</p>

        <button class="btn-blue" (click)="ngContacto()">
          {{ 'taxagile.hero.btn' | translate }} 
        </button>
    </div>
    <figure class="m-0">
        <img src="assets/images/laptop4.png" alt="laptop" class="laptop">
    </figure>
</section>
</div>

<div class="bg-white">
  <section class="solucion w-fullscreen">
    <div class="img-content">
        <img src="assets/images/chart.png" alt="chart">
    </div>
    <div class="text">
        <h2 class="color-blue Font-Inter-Black">{{ 'taxagile.solution.title' | translate }}</h2>
    
        <p class="color-blue Font-Helvetica-Roman">{{ 'taxagile.solution.parraf' | translate }}</p>

        <button mat-button class="color-purple Font-Inter-Bold" (click)="ngContacto()">
          {{ 'taxagile.solution.btn' | translate }}
          <mat-icon>
            arrow_right
          </mat-icon>
        </button>
    </div>

</section>

</div>

<div class="bg-equipo">
  <section class="equipo w-fullscreen">

      <div class="text">
          <h2 class="color-blue Font-Inter-Black">{{ 'taxagile.team.title' | translate }}</h2>

          <p class="color-blue Font-Helvetica-Roman">{{ 'taxagile.team.parraf' | translate }}</p>

          <button mat-button class="color-purple Font-Inter-Bold" routerLink="/nosotros">
            {{ 'taxagile.team.btn' | translate }}
            <mat-icon>
              arrow_right
            </mat-icon>
          </button>
      </div>

      <div class="img-content">
          <img src="assets/images/computer2.png" alt="computer">
      </div>

  </section>
</div>


<app-clientes></app-clientes>
<app-certificacion></app-certificacion>
<app-contacto></app-contacto>
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'app-taxchecker',
  templateUrl: './taxchecker.component.html',
  styleUrls: ['./taxchecker.component.sass'],
})
export class TaxcheckerComponent implements OnInit {
  constructor(
    private location: Location, 
    private router: Router,
    protected $gaService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.$gaService.pageView('/taxchecker', 'Vista TaxChecker')
  }
  ngContacto(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.router
      .navigate([this.location.path()], { fragment: 'contacto' })
      .finally(() => {
        this.router.onSameUrlNavigation = 'ignore';
      });
  }
}

<div class="bg-contacto">
    <section id="contacto" class="contacto w-fullscreen">
        <div class="form-m">
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <p class="color-blue Font-Inter-Bold">{{ 'contact.title' | translate }}</p>
                <h2 class="color-blue Font-Inter-Black">{{ 'contact.subtitle' | translate }}</h2>

                <div class="form-group">
                    <label class="color-blue Font-Inter-SemiBold" for="name">{{ 'contact.form.name' | translate }}</label>
                    <input id="name" type="text" formControlName="name" [ngClass]="{'border-red': submitted && f.name.errors}">
                    <!-- <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">El nombre es requerido</div>
                    </div> -->
                </div>
                <div class="form-group">
                    <label class="color-blue Font-Inter-SemiBold" for="phone">{{ 'contact.form.phone' | translate }}</label>
                    <input id="phone" type="text" formControlName="phone" [ngClass]="{'border-red': submitted && f.name.errors}">
                    <!-- <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">El nombre es requerido</div>
                    </div> -->
                </div>
                <div class="form-group">
                    <label class="color-blue Font-Inter-SemiBold" for="ruc">{{ 'contact.form.ruc' | translate }}</label>
                    <input type="text" id="ruc" formControlName="ruc" [ngClass]="{'border-red': submitted && f.ruc.errors}">
                    <!-- <div *ngIf="submitted && f.ruc.errors" class="invalid-feedback">
                        <div *ngIf="f.ruc.errors.required">El ruc es requerido</div>
                    </div> -->
                </div>
                <div class="form-group">
                    <label class="color-blue Font-Inter-SemiBold" for="rubro">{{ 'contact.form.rol' | translate }}</label>
                    <input id="rubro" type="text" formControlName="rubro" [ngClass]="{'border-red': submitted && f.rubro.errors}">
                    <!-- <div *ngIf="submitted && f.rubro.errors" class="invalid-feedback">
                        <div *ngIf="f.rubro.errors.required">El rubro es requerido</div>
                    </div> -->
                </div>
                <div class="form-group">
                    <label class="color-blue Font-Inter-SemiBold" for="email">{{ 'contact.form.email' | translate }}</label>
                    <input id="email" type="email" formControlName="email" [ngClass]="{'border-red': submitted && f.email.errors}">
                    <!-- <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div> -->
                </div>
                <div class="form-group">
                    <label class="color-blue Font-Inter-SemiBold" for="mensaje">{{ 'contact.form.message' | translate }}</label>
                    <textarea id="mensaje" formControlName="mensaje" [ngClass]="{'border-red': submitted && f.email.errors}"></textarea>
                </div>
                <div class="form-group tyc">
                    <mat-checkbox class="color-blue" formControlName="acceptTerms" [ngClass]="{ 'color-rose': submitted && f.acceptTerms.errors }">{{ 'contact.form.checkbox' | translate }}</mat-checkbox>
                    <span class="color-blue" (click)="goPoliticas()">{{ 'contact.form.link' | translate }}</span>
                </div>
                <div [ngClass]="{'not-opacity': formValid, 'opacity': !formValid}" class="color-rose mb-10">
                    {{ 'contact.form.response' | translate }}
                </div>
                <div class="form-group">
                    <button class="Font-Inter-ExtraBold btn-rose" type="submit">
                        {{ 'contact.form.btn' | translate }}
                    </button>
                </div>
    
            </form>
        </div>
        <mat-card>
            <div class="content">
                <p class="color-blue Font-Inter-Bold">{{ 'contact.title' | translate }}</p>
                <h2 class="color-blue Font-Inter-Black">{{ 'contact.subtitle' | translate }}</h2>

                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="color-blue Font-Inter-SemiBold" for="name">{{ 'contact.form.name' | translate }}</label>
                        <input id="name" type="text" formControlName="name" [ngClass]="{'border-red': submitted && f.name.errors}" (keypress)="validateKeyLetters($event)">
                        <!-- <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">El nombre es requerido</div>
                        </div> -->
                    </div>
                    <div class="form-group">
                        <label class="color-blue Font-Inter-SemiBold" for="phone">{{ 'contact.form.phone' | translate }}</label>
                        <input id="phone" type="text" formControlName="phone" (keypress)="validateOnlyNumber($event)" (keypress)=" validatePhone($event)" [ngClass]="{'border-red': submitted && f.phone.errors}">
                        <!-- <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">El nombre es requerido</div>
                        </div> -->
                    </div>
                    <div class="form-group">
                        <label class="color-blue Font-Inter-SemiBold" for="ruc">{{ 'contact.form.ruc' | translate }}</label>
                        <input type="text" id="ruc" formControlName="ruc" (keypress)="validateOnlyNumber($event)" (keypress)=" validateRUC($event)" [ngClass]="{'border-red': submitted && f.ruc.errors}">
                        <!-- <div *ngIf="submitted && f.ruc.errors" class="invalid-feedback">
                            <div *ngIf="f.ruc.errors.required">El ruc es requerido</div>
                        </div> -->
                    </div>
                    <div class="form-group">
                        <label class="color-blue Font-Inter-SemiBold" for="rubro">{{ 'contact.form.rol' | translate }}</label>
                        <input id="rubro" type="text" formControlName="rubro" [ngClass]="{'border-red': submitted && f.rubro.errors}">
                        <!-- <div *ngIf="submitted && f.rubro.errors" class="invalid-feedback">
                            <div *ngIf="f.rubro.errors.required">El rubro es requerido</div>
                        </div> -->
                    </div>
                    <div class="form-group">
                        <label class="color-blue Font-Inter-SemiBold" for="email">{{ 'contact.form.email' | translate }}</label>
                        <input id="email" type="email" formControlName="email" [ngClass]="{'border-red': submitted && f.email.errors}">
                        <!-- <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div> -->
                    </div>

                    <div class="form-group">
                        <label class="color-blue Font-Inter-SemiBold" for="mensaje">{{ 'contact.form.message' | translate }}</label>
                        <textarea formControlName="mensaje" id="mensaje" [ngClass]="{'border-red': submitted && f.email.errors}"></textarea>
                    </div>
                    <div class="form-group tyc">
                        <mat-checkbox class="color-blue" formControlName="acceptTerms" [ngClass]="{ 'color-rose': submitted && f.acceptTerms.errors }">{{ 'contact.form.checkbox' | translate }} </mat-checkbox>
                        <span class="color-blue" (click)="goPoliticas()"> {{ 'contact.form.link' | translate }}</span>
                    </div>

                    <div *ngIf="formValid" class="color-rose mb-10">
                        {{ 'contact.form.response' | translate }}
                    </div>

                    <div class="form-group">
                        <button [disabled]="show" class="Font-Inter-ExtraBold btn-rose btn-spinner" type="submit">{{ 'contact.form.btn' | translate }}
                            <mat-progress-spinner
                            class="spinner"
                            [mode]="mode"
                            diameter="30"
                            *ngIf="show"
                            >
                            </mat-progress-spinner>
                        </button>
 
                    </div>
    
                </form>

            </div>
    
    
            <img src="assets/images/contacto.png" alt="contacto">
        </mat-card>
    
    </section>
</div>

<div class="bg-white">
  <section class="nosotros w-fullscreen">

    <div class="text">
        <h2 class="color-blue Font-Inter-Black">{{ 'nosotros.hero.title' | translate }}</h2>
        <p class="color-blue Font-Helvetica-Roman">{{ 'nosotros.hero.parraf' | translate }}</p>
    </div>

    <img src="assets/images/nosotros.png" alt="nosotros">
</section>
</div>

<div class="bg-white">
  <section class="vision w-fullscreen">

    <div class="text">
        <h2 class="color-blue Font-Inter-Black">{{ 'nosotros.vision.title' | translate }}</h2>

        <p class="color-blue Font-Helvetica-Roman">{{ 'nosotros.vision.parraf' | translate }}</p>
        <button mat-button class="color-purple Font-Inter-Bold" (click)="ngContacto()">
          {{ 'nosotros.vision.btn' | translate }} 
          <mat-icon>
            arrow_right
          </mat-icon>
        </button>
    </div>

    <img src="assets/images/vision.png" alt="vision">
</section>
</div>

<div class="bg-white">
  <section class="mision w-fullscreen">
    <img src="assets/images/mision.png" alt="mision">
    <div class="text">
        <h2 class="color-blue Font-Inter-Black">{{ 'nosotros.mision.title' | translate }}</h2>
        <p class="color-blue Font-Helvetica-Roman">{{ 'nosotros.mision.parraf' | translate }}</p>
        <button mat-button class="color-purple Font-Inter-Bold" (click)="ngContacto()">
          {{ 'nosotros.mision.btn' | translate }} 
          <mat-icon>
            arrow_right
          </mat-icon>
        </button>
    </div>
</section>
</div>


<app-elegirtaxtech></app-elegirtaxtech>
<app-clientes></app-clientes>
<app-contacto></app-contacto>

import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ContactService } from '../contact.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.sass'],
})
export class ContactoComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  origen: string;
  formValid: boolean;
  show: boolean;
  color: ThemePalette = 'accent';
  mode: ProgressSpinnerMode = 'indeterminate';
  constructor(
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    protected $gaService: GoogleAnalyticsService,
    private activatedRoute:ActivatedRoute,
    private router: Router
  ) {

    this.origen = this.activatedRoute.snapshot.url[0].path;
  }

  ngOnInit(): void {
    this.$gaService.pageView('/home', 'Vista home')

    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      ruc: ['', Validators.required],
      rubro: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mensaje: ['', Validators.required],
      acceptTerms: ['', Validators.requiredTrue]
    });
  }
  goPoliticas() {
    this.router.navigate(['politica-privacidad']);
  }
  get f(): any {
    return this.contactForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.contactForm.invalid) {
        return;
    }
    this.show = true;
    const body = {
      "personalizations": [
        {
          to: [
            { email: 'jessica.torres@taxtech.pe' }      
            // { email: 'erika.vidal@taxtech.pe' }
          ],
          "cc": [
            {
              "email": "miguel.quepuy@taxtech.pe"
            }
          ],
        },
        
      ],
      "from": { "email": "hola@taxtech.pe" },
      "subject": 'Contacto TaxTech',
      "content": [
        {
          "type": 'text/html',
          "value": `<html>
                      <head></head>
                      <body>
                        <span style="font-weight: 700"> Nombres completos:</span> ${this.f.name.value} <br>
                        <span style="font-weight: 700"> Teléfono:</span> ${this.f.phone.value} <br>
                        <span style="font-weight: 700">Ruc:</span> ${this.f.ruc.value} <br> 
                        <span style="font-weight: 700">Rubro:</span> ${this.f.rubro.value} <br> 
                        <span style="font-weight: 700">Correo:</span> ${this.f.email.value} <br> 
                        <span style="font-weight: 700">Mensaje:</span> ${this.f.mensaje.value} <br> 
                        <span style="font-weight: 700">Origen:</span> ${this.origen} </body>
                    </html>`,
        },
      ],
    };

    this.contactService.sendContact(body).subscribe((e) => {
      console.log(e);
      // swal.fire({
      //   icon: 'success',
      //   title: 'Datos enviados exitosamente',
      //   showClass: {
      //     popup: 'animate__animated animate__fadeInDown'
      //   },
      //   hideClass: {
      //     popup: 'animate__animated animate__fadeOutUp'
      //   }
      // })
      this.formValid = true;
      this.show = false;
      this.onReset();
      setTimeout(() => {
        this.formValid = false;
      }, 5000);
      
    });
  }

  validateOnlyNumber(event) {
    const value = String.fromCharCode(event.keyCode);
    
    const regex = /^[0-9]$/;
     
    if (!regex.test(value)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  validateKeyLetters(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/^[a-zA-Z\s]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  validatePhone(event) {
    const phone = (this.f.phone.value).toString();

    if (phone.length + 1 > 9 ) {  
      event.preventDefault();
    }
    
  }
  validateRUC(event) {
    const ruc = (this.f.ruc.value).toString();

    if (ruc.length + 1 > 11 ) {  
      event.preventDefault();
    }
    
  }
  onReset() {
    this.submitted = false;
    this.contactForm.reset();
  }
}

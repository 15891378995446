<div class="bg-white">
    <mat-toolbar class="w-fullscreen">
        <div>
            <a routerLink="/home">
                <img src="assets/images/logo.svg" alt="tax tech">
            </a>
        </div>
        <div class="menu-m">
            <button mat-icon-button (click)="openDialog()">
              <mat-icon>
                menu
              </mat-icon>
            </button>
        </div>
        <div class="menu-d">
            <ul class="navigation-items">
                <li>
                    <button #button mat-button [matMenuTriggerFor]="herramientas" #herramientasTrigger="matMenuTrigger" (mouseenter)="buttonEnter(herramientasTrigger)" (mouseleave)="buttonLeave(herramientasTrigger, button)" style="z-index:1050">
                        {{ 'header.tools.title' | translate }}
                        <img src="assets/images/arrow-down.png" alt="arrow-down">
                    </button>
                    <mat-menu #herramientas="matMenu" class="item-first"  [hasBackdrop]="false">
                        <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(herramientasTrigger, button)">
                            <a mat-menu-item routerLink="taxchecker">
                                <div class="bg-taxchecker d-inline-block"></div>
                                <div class="d-inline-block p-1">
                                    <h2 class="color-title-btn Font-Inter-SemiBold font-16">{{ 'header.tools.first.title' | translate }}</h2>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.tools.first.parraf_one' | translate }}</p>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.tools.first.parraf_two' | translate }}</p>
                                </div>
                            </a>
                            <a mat-menu-item routerLink="deducesmart">
    
                                <div class="bg-deducesmart d-inline-block"></div>
                                <div class="d-inline-block p-1">
                                    <h2 class="color-title-btn Font-Inter-SemiBold font-16">{{ 'header.tools.second.title' | translate }}</h2>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.tools.second.parraf_one' | translate }}</p>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.tools.second.parraf_two' | translate }}</p>
                                </div>
                            </a>
                            <a mat-menu-item routerLink="plesmart">
    
                                <div class="bg-plesmart d-inline-block"></div>
                                <div class="d-inline-block p-1">
                                    <h2 class="color-title-btn Font-Inter-SemiBold font-16">{{ 'header.tools.third.title' | translate }}</h2>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.tools.third.parraf_one' | translate }}</p>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.tools.third.parraf_two' | translate }}</p>
                                </div>
    
                            </a>
                            <a mat-menu-item routerLink="timetaxsupport">
    
                                <div class="bg-timetaxsupport d-inline-block"></div>
                                <div class="d-inline-block p-1">
                                    <h2 class="color-title-btn Font-Inter-SemiBold font-16">{{ 'header.tools.fourth.title' | translate }}</h2>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.tools.fourth.parraf_one' | translate }}</p>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.tools.fourth.parraf_two' | translate }}</p>
                                </div>
    
                            </a>
                            <a mat-menu-item href="http://www.eol.pe/" target="_blank" rel="noopener">
    
                                <div class="bg-emiteonline d-inline-block"></div>
                                <div class="d-inline-block p-1">
                                    <h2 class="color-title-btn Font-Inter-SemiBold font-16">{{ 'header.tools.fifth.title' | translate }}</h2>
                                    <p class="font-14 Font-Inter-Medium color-parraf"> {{ 'header.tools.fifth.parraf_one' | translate }}</p>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.tools.fifth.parraf_two' | translate }}</p>
                                </div>
    
                            </a>
                        </span>
                    </mat-menu>
                </li>
                <li>
                    <button #button mat-button [matMenuTriggerFor]="servicios" #serviciosTrigger="matMenuTrigger" (mouseenter)="buttonEnter(serviciosTrigger)" (mouseleave)="buttonLeave(serviciosTrigger, button)" style="z-index:1050">
                        {{ 'header.services.title' | translate }}
                         <img src="assets/images/arrow-down.png" alt="arrow-down">
                    </button>
    
                    <mat-menu #servicios="matMenu" class="item-second" [hasBackdrop]="false">
                        <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(serviciosTrigger, button)">
                            <a mat-menu-item routerLink="taxagile">
    
                                <div class="bg-taxagile d-inline-block"></div>
                                <div class="d-inline-block p-1">
                                    <h2 class="color-title-btn Font-Inter-SemiBold font-16">{{ 'header.services.first.title' | translate }}</h2>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.services.first.parraf_one' | translate }}</p>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.services.first.parraf_two' | translate }}</p>
    
                                </div>
                            </a>
                            <a mat-menu-item routerLink="consultoriaespecializada">
    
                                <div class="bg-consultoria d-inline-block"></div>
                                <div class="d-inline-block p-1">
                                    <h2 class="color-title-btn Font-Inter-SemiBold font-16">{{ 'header.services.second.title' | translate }}</h2>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.services.second.parraf_one' | translate }}</p>
                                    <p class="font-14 Font-Inter-Medium color-parraf">{{ 'header.services.second.parraf_two' | translate }}</p>
    
                                </div>
                            </a>
                        </span>
                    </mat-menu>
                </li>
                <li>
                    <button mat-button routerLink="nosotros">{{ 'header.us.title' | translate }}</button>
                </li>
            </ul>
        </div>
    
        <div class="menu-d menu-icons">
            <ul class="navigation-items">
                <li (click)="ngContacto()">
                    <button mat-button>{{ 'header.contact.title' | translate }}</button>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/taxtechperu" target="_blank" rel="noopener" class="linkedin"></a>
                </li>
                <li>
                    <a href="https://www.facebook.com/TaxTech" target="_blank" rel="noopener" class="facebook"></a>
                </li>
                <li *ngIf="currentLanguage == 'es'" (click)="setLanguage('en')" class="cursor-p">
                    <a >
                        <img class="img-flag" src="assets/images/united-states.png" alt="united-states">
                    </a>
                </li>
                <li *ngIf="currentLanguage == 'en'" (click)="setLanguage('es')" class="cursor-p">
                    <a>
                        <img class="img-flag" src="assets/images/spain.png" alt="spain">
                    </a>
                </li>
            </ul>
        </div>
    
    </mat-toolbar>
    
</div>

<div class="bg-white">
    <section class="hero w-fullscreen">
        <div class="hero-text">
            <div class="title-icon">
                <img src="assets/images/consultoria-circle.svg" alt="consultoria especializada">
                <span class="color-rose Font-Inter-ExtraBold tagile">{{ 'advisory.hero.logo' | translate }}</span>
                <span class="color-blue Font-Inter-ExtraBold tech">TaxTech</span>
            </div>
            <h2 class="color-blue Font-Inter-Black">{{ 'advisory.hero.title' | translate }}</h2>
    
            <p class="color-blue-soft Font-Helvetica-Roman">{{ 'advisory.hero.parraf' | translate }}</p>
    
            <button class="btn-blue" (click)="ngContacto()">
                {{ 'advisory.hero.btn' | translate }} 
            </button>
        </div>
        <figure class="m-0">
            <img src="assets/images/consultoria.png" alt="laptop" class="laptop">
        </figure>
    </section>
</div>

<div class="bg-white">
    <section class="solucion w-fullscreen">
        <div class="img-content">
            <img src="assets/images/phone3.png" alt="consultoria especializada">
        </div>
        <div class="text">
            <h2 class="color-blue Font-Inter-Black">{{ 'advisory.solution.title' | translate }}</h2>
            
            <p class="color-blue-soft Font-Helvetica-Roman">{{ 'advisory.solution.parraf' | translate }}</p>
        
            <button mat-button class="color-purple Font-Inter-Bold" (click)="ngContacto()">
                {{ 'advisory.solution.btn' | translate }}
              <mat-icon>
                arrow_right
              </mat-icon>
            </button>
        </div>
    
    </section>
</div>

<app-elegirtaxtech></app-elegirtaxtech>
<app-clientes></app-clientes>
<app-certificacion></app-certificacion>
<app-contacto></app-contacto>
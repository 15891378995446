import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent {
  constructor(public translate: TranslateService) {
    this.setAppLanguage();
  }
  setAppLanguage(): void {

    let  currentLanguage  =  this.translate.getBrowserLang();
    if (currentLanguage !== 'es' && currentLanguage !== 'en') {
      currentLanguage = 'en';
    }

    this.translate.setDefaultLang(currentLanguage);
    this.translate.use(currentLanguage);
    console.log(currentLanguage);
  }
}



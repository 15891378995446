import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import {
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit {
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;
  currentLanguage: string;
  constructor(
    public dialog: MatDialog,
    private location: Location,
    private router: Router,
    private ren: Renderer2,
    private el: ElementRef,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentLanguage  =  this.translate.getBrowserLang();
    console.log(this.currentLanguage);
    
  }

  menuenter() {
    
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger, button) {
    setTimeout(() => {
      if (!this.enteredButton) {
        
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80)
  }

  buttonEnter(trigger) {
 
    setTimeout(() => {
      if(this.prevButtonTrigger && this.prevButtonTrigger != trigger){
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      }
      else if (!this.isMatMenuOpen) {
        console.log(this.prevButtonTrigger);
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        trigger.openMenu()
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
    })
  }

  buttonLeave(trigger, button) {
    
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 100)
  }

  ngContacto(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.router
      .navigate([this.location.path()], { fragment: 'contacto' })
      .finally(() => {
        this.router.onSameUrlNavigation = 'ignore'; // Restore config after navigation completes
      });
    // setTimeout(() => {
    //   this.el.nativeElement.querySelector('#contacto').scrollIntoView();
    // }, 400);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      // height: '534px',
      width: '100%',
      disableClose: true,
      hasBackdrop: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  setLanguage(lang): void {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.currentLanguage = lang;
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.sass'],
})
export class DialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private router: Router
  ) {}
 
  goSection(ruta): void {
    this.dialogRef.close();
    this.router.navigate([`/${ruta}`]);
  }
}

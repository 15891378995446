<div class="bg-white">
  <section class="nosotros w-fullscreen">
    <div class="text">
        <h2 class="color-blue Font-Inter-Black">{{ 'elegir.title' | translate }}</h2>
        <p class="color-blue-soft Font-Helvetica-Roman">{{ 'elegir.parraf' | translate }}</p>

        <button mat-button class="color-purple Font-Inter-Bold" routerLink="/nosotros">
          {{ 'elegir.btn' | translate }}
          <mat-icon>
            arrow_right
          </mat-icon>
        </button>
    </div>
    <div>
        <img src="assets/images/nosotros-home.png" alt="nosotros">
    </div>
</section>

</div>

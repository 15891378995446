import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RoutingModule } from './routing/routing.module';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from './material/material.module';
import { HeaderComponent } from './header/header.component';
import { DialogComponent } from './header/header.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ContactoComponent } from './contacto/contacto.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { TaxcheckerComponent } from './taxchecker/taxchecker.component';
import { PlesmartComponent } from './plesmart/plesmart.component';
import { DeducesmartComponent } from './deducesmart/deducesmart.component';
import { TaxagileComponent } from './taxagile/taxagile.component';
import { ClientesComponent } from './clientes/clientes.component';
import { CertificacionComponent } from './certificacion/certificacion.component';
import { ConsultoriaComponent } from './consultoria/consultoria.component';
import { ElegirtaxtechComponent } from './elegirtaxtech/elegirtaxtech.component';
import { PoliticaComponent } from './politica/politica.component';
import { PrivacidadComponent } from './privacidad/privacidad.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { HttpClient, HttpClientModule} from '@angular/common/http';
import { TimeTaxSupportComponent } from './time-tax-support/time-tax-support.component';
import { MetaModule } from '@ngx-meta/core';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    DialogComponent,
    ContactoComponent,
    FooterComponent,
    TaxcheckerComponent,
    PlesmartComponent,
    DeducesmartComponent,
    TaxagileComponent,
    ClientesComponent,
    CertificacionComponent,
    ConsultoriaComponent,
    ElegirtaxtechComponent,
    PoliticaComponent,
    NosotrosComponent,
    TimeTaxSupportComponent,
    PrivacidadComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RoutingModule,
    MaterialModule,
    CarouselModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxGoogleAnalyticsModule.forRoot('UA-180593102-1'),
    NgxGoogleAnalyticsRouterModule,
    MetaModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [Meta],
  bootstrap: [AppComponent],
  entryComponents: [DialogComponent],
})
export class AppModule {}
